import Swiper from 'swiper/js/swiper';

export default class VariationSwiper {
	constructor(element) {
		if (!(element instanceof HTMLElement)) {
			throw new TypeError('Invalid element');
		}

		this.element = element;

		this.initSlider();
	}

	initSlider(){
		this.swiperObj =  new Swiper(this.element, {
			speed: 400,
			allowTouchMove: true,
			effect: 'slide',
			autoplay: false,
			navigation: {
				nextEl: this.element.parentNode.querySelector('.swiper-button-next'),
				prevEl: this.element.parentNode.querySelector('.swiper-button-prev'),
			},
			pagination: {
				el: this.element.querySelector('.swiper-pagination'),
				type: 'bullets',
				clickable: true
			},

			slidesPerView: 1,
			spaceBetween: 0,
			slidesPerGroup: 1,
			// Responsive breakpoints
			breakpoints: {
				// when window width is >= 480px
				767: {
					slidesPerView: 2,
					spaceBetween: 30,
					slidesPerGroup: 2
				},
				// when window width is >= 640px
				991: {
					slidesPerView: 3,
					spaceBetween: 40,
					slidesPerGroup: 3
				}
			}
		});
	}
}
