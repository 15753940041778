export default class AnchoredCTA{
	constructor(element) {
		if (!(element instanceof HTMLElement)) {
			throw new TypeError('Invalid element');
		}

		this.bar = element;
		this.scroll = document.scrollY;

		window.addEventListener('scroll', this.check.bind(this), { passive: true });
	}

	check(ev){
		let scrolledDown = (window.scrollY > this.scroll),
			show = ((document.body.clientHeight/2) - window.innerHeight) < window.scrollY,
			footerHide = ((document.body.clientHeight - 200) - window.innerHeight) < window.scrollY;

		if(scrolledDown && (show && !footerHide)) {
			this.bar.classList.add('active');
		} else {
			this.bar.classList.remove('active');
		}

		this.scroll = window.scrollY;
	}
}
