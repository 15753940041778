export default class StickyScrolling {
	constructor(element) {
		if(!(element instanceof HTMLElement)) {
			throw new TypeError('Invalid element');
		}

		this.wrapper = element;
		this.listItems = this.wrapper.querySelectorAll('.groups .group');
		this.navItems = this.wrapper.querySelectorAll('.tabs a');

		this.updateSections();

		window.addEventListener('scroll', this.updateSections.bind(this), { passive : true });

		if(this.navItems.length){
			this.navItems.forEach(el => {
				el.addEventListener('click', this.moveToSection.bind(this));
			});
		}
	}

	moveToSection(ev){
		ev.preventDefault();

		const id = ev.target.dataset.id;

		if(typeof id === 'undefined')
			return false;

		const section = this.listItems.filter(el => {
			return (typeof el.dataset.id !== 'undefined' && el.dataset.id === id);
		});

		section[0].scrollIntoView({
			behavior: 'smooth'
		});
	}

	updateSections(){
		this.listItems.forEach(el => {
			if(el.getBoundingClientRect().top <= 0){
				el.classList.add('hit');
			} else {
				el.classList.remove('hit');
			}
		});

		this.updateMenu();
	}

	updateMenu(){
		const activeItems = this.listItems.filter(el => {
			return el.classList.contains('hit');
		});

		if(!activeItems.length || this.navItems === null)
			return false;

		const id = activeItems.slice(-1)[0].dataset.id;

		this.navItems.forEach(el =>{
			el.classList.remove('active');

			if(typeof el.dataset.id !== 'undefined' && id === el.dataset.id){
				el.classList.add('active');
			}
		});
	}
}