export default class Accordion {
	constructor(element) {
		if(!(element instanceof HTMLElement)) {
			throw new TypeError('Invalid element');
		}

		this.container = element;
		this.items = Array.from(element.querySelectorAll('.accordion__item'));
		this.active = null;

		this.container.accordion = this;

		this.items.forEach(item => {
			item.classList.add('closed');

			if(item.querySelector('.accordion__title') !== null) {
				item.querySelector('.accordion__title').addEventListener('click', (ev) => {
					this.toggle(ev.target.closest('.accordion__item'));
				});
			}

			if(item.querySelector('.next') !== null){
				item.querySelectorAll('.next').forEach(el => {
					el.addEventListener('click', ev => {
						this.close(ev.currentTarget.closest('.accordion__item'));
						this.open(ev.currentTarget.closest('.accordion__item').nextElementSibling);
					});
				});
			}
		});

		window.addEventListener('resize', e => this.resize(), {
			passive: true,
		});

		this.resize();

		return this;
	}

	toggle(item) {
		if(this.items.indexOf(item) === -1) {
			return false;
		}

		const open = (this.active !== item);

		item.classList.toggle('closed', !open);

		let copy = item.querySelector('.accordion__content');

		if(!copy) {
			return;
		}

		copy.style.height = 'auto';
		// copy.style.height = copy.offsetHeight + 'px';

		if(this.active && open) {
			this.close(this.active);
		}

		this.active = (open) ? item : null;

		return true;
	}

	open(item) {
		if(this.items.indexOf(item) === -1) {
			return false;
		}

		if(this.active === item) {
			return true;
		}

		let copy = item.querySelector('.accordion__content');

		if(!copy) {
			return;
		}

		copy.style.height = 'auto';
		// copy.style.height = copy.offsetHeight + 'px';

		item.classList.remove('closed');

		if(this.active) {
			this.close(this.active);
		}

		this.active = item;

		return true;
	}

	close(item) {
		if(this.items.indexOf(item) === -1) {
			return false;
		}

		if(this.active !== item) {
			return true;
		}

		this.active.classList.add('closed');
		this.active = null;

		// jQuery(document.body).trigger("update_checkout");

		return true;
	}

	resize() {
		this.items.forEach(item => {
			const copy = item.querySelector('.accordion__content');

			if(!copy) {
				return;
			}

			item.classList.remove('closed');

			copy.style.height = 'auto';
			// copy.style.height = copy.offsetHeight + 'px';

			if(this.active !== item) {
				item.classList.add('closed');
			}
		});
	}
};
