export default class ClassDropdown {
	constructor(element){
		if (!(element instanceof HTMLElement)) {
			throw new TypeError('Invalid element');
		}

		this.element = element;
		this.label = this.element.querySelector('label');
		this.choicesWrapper = this.element.querySelector('.choices');

		this.initEvents();
	}

	initEvents(){
		if(this.label !== null){
			this.label.addEventListener('click', this.toggle.bind(this), {}, false);
		}

		if(this.choicesWrapper !== null){
			if(this.choicesWrapper.querySelector('input[type=checkbox], input[type=radio]') !== null){
				this.choicesWrapper.querySelectorAll('input[type=checkbox], input[type=radio]').forEach(el => {
					el.addEventListener('change', this.hide.bind(this));
				})
			}
		}

	}

	hide(ev) {
		console.log("Current Target: ", ev.target.parentElement.innerText.trim());
		if(typeof ev.currentTarget.value !== 'undefined'){
			let parent = ev.target.parentElement;
			if(parent) {
				let val = (parent.innerText) ? parent.innerText : ev.currentTarget.value;
				this.label.innerHTML = val;
			} else {
				this.label.innerHTML = ev.currentTarget.value;
			}
		}

		if(this.element !== null){
			this.element.classList.remove('open');
		}
	}

	toggle(ev) {
		ev.stopPropagation();

		if(this.element !== null){
			if(this.element.classList.contains('open')){
				document.body.removeEventListener('click', this.hide.bind(this), { once: true });
			} else {
				document.body.addEventListener('click', this.hide.bind(this), { once: true });
			}

			this.element.classList.toggle('open');
		}
	}
}