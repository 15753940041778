import Swiper from 'swiper/js/swiper';

export default class HomeSlider{
	constructor(element) {
		if(!(element instanceof HTMLElement)) {
			throw new TypeError('Invalid element');
		}

		this.element = element;
		this.nav = this.element.nextElementSibling;
		this.images   = [];

		this.initSlider();
		this.getImages();
		this.initNav();
	}

	initSlider(el){
		this.swiperObj = new Swiper(this.element, {
			speed: 400,
			allowTouchMove: false,
			effect : 'slide',
  			spaceBetween: 20,
			// fadeEffect: {
			// 	crossFade: true,
			// 	disableOnInteraction: false,
			// 	waitForTransition: false,
			// },
			autoplay: {
				delay: 9000,
			},
			on: {
				init: () => {
					if(this.nav !== null){
						let firstItem = this.nav.querySelectorAll('li .item')[0];
						firstItem.classList.add('active');

						if(typeof firstItem.dataset.image !== 'undefined'){
							this.element.parentElement.style.backgroundImage = 'url(' + firstItem.dataset.image + ')';
						}
					}
				},
				slideChange: () =>{
					if(this.nav !== null){
						this.nav.querySelectorAll('li').forEach(el => el.querySelector('.item').classList.remove('active'));
						this.nav.querySelectorAll('li .item')[this.swiperObj.activeIndex].classList.add('active');
					}

					if(this.images.length){
						this.element.parentElement.style.backgroundImage = 'url(' + this.images[this.swiperObj.activeIndex] + ')';
					}
				}
			},
		});
	}

	initNav(){
		if(this.nav.querySelector('li a') !== null){
			this.nav.querySelectorAll('li a').forEach(el => {
				el.addEventListener('click', this.changeSlide.bind(this));
			});
		}
	}

	changeSlide(ev){
		let index = [...this.nav.querySelectorAll('li')].indexOf(ev.currentTarget.parentNode);

		ev.preventDefault();
		this.swiperObj.slideTo(index);
	}

	getImages() {
		if(this.nav.querySelectorAll('li') === null)
			return false;

		this.nav.querySelectorAll('li').forEach(item => {
			const img     = document.createElement('img'),
				  canvas  = document.createElement("canvas"),
				  context = canvas.getContext('2d'),
				  idx     = [...item.parentNode.children].indexOf(item);

			let dataURL;

			img.setAttribute('src', item.querySelector('a').dataset.image);
			img.setAttribute('crossOrigin', 'Anonymous');

			console.log(img);

			img.onload = () => {
				canvas.width  = img.width;
				canvas.height = img.height;

				context.drawImage(img, 0, 0);

				dataURL = canvas.toDataURL("image/jpeg", 0.8);

				this.images[idx] = dataURL;
			}
		});
	}
};
