export default {
	pageNav: null,
	init: function () {
		this.pageNav = document.querySelector('#page-nav');

		if(document.querySelector('#nav-trigger') !== null){
			document.querySelectorAll('#nav-trigger').forEach(el => {
				el.addEventListener('click', ev => {
					ev.preventDefault();

					this.pageNav.classList.toggle('menu-open');
					document.body.classList.toggle('page-menu-open');

					if(this.pageNav.querySelector('.menu-item') !== null){
						this.pageNav.querySelectorAll('.menu-item').forEach(el => {
							el.classList.remove('sub-menu-open');
						});
					}
				});
			});
		}

		if(this.pageNav !== null && this.pageNav.querySelector('.sub-menu-button') !== null){
			this.pageNav.querySelectorAll('.sub-menu-button').forEach(el => {
				el.addEventListener('click', ev => {
					ev.preventDefault();

					ev.currentTarget.closest('.menu-item').classList.toggle('sub-menu-open');
					ev.currentTarget.closest('.nav').classList.toggle('has-sub-menu-open');
				});
			});
		}
	},
}