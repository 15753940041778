
export default {
	easings: {
		linear(t) {
			return t;
		},
		easeInQuad(t) {
			return t * t;
		},
		easeOutQuad(t) {
			return t * (2 - t);
		},
		easeInOutQuad(t) {
			return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
		},
		easeInCubic(t) {
			return t * t * t;
		},
		easeOutCubic(t) {
			return (--t) * t * t + 1;
		},
		easeInOutCubic(t) {
			return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
		},
		easeInQuart(t) {
			return t * t * t * t;
		},
		easeOutQuart(t) {
			return 1 - (--t) * t * t * t;
		},
		easeInOutQuart(t) {
			return t < 0.5 ? 8 * t * t * t * t : 1 - 8 * (--t) * t * t * t;
		},
		easeInQuint(t) {
			return t * t * t * t * t;
		},
		easeOutQuint(t) {
			return 1 + (--t) * t * t * t * t;
		},
		easeInOutQuint(t) {
			return t < 0.5 ? 16 * t * t * t * t * t : 1 + 16 * (--t) * t * t * t * t;
		}
	},
	init: function () {

		if (document.querySelector("a.animate-scroll") !== null) {
			document.querySelectorAll("a.animate-scroll").forEach(el => {
				el.addEventListener('click', this.initScroll.bind(this));
			});
		}
	},
	initScroll: function (ev) {
		if (typeof ev.target.getAttribute('href') === 'undefined') {
			console.warn('[AnimateScroll] Href attribute undefined');
			return;
		}
		if (ev.target.getAttribute('href') === '#') {
			return;
		}
		if (document.querySelector(ev.target.getAttribute('href')) === null) {
			console.warn('[AnimateScroll] Referenced element does not exist - ' + ev.target.getAttribute('href'));
			return;
		}

		ev.preventDefault();

		this.scroll(document.querySelector(ev.target.getAttribute('href')), 420, 'easeInQuad');
	},
	scroll: function (destination, duration = 200, easing = 'linear') {
		const start = window.pageYOffset;
		const startTime = 'now' in window.performance ? performance.now() : new Date().getTime();

		const documentHeight = Math.max(document.body.scrollHeight, document.body.offsetHeight, document.documentElement.clientHeight, document.documentElement.scrollHeight, document.documentElement.offsetHeight);
		const windowHeight = window.innerHeight || document.documentElement.clientHeight || document.getElementsByTagName('body')[0].clientHeight;
		const destinationOffset = typeof destination === 'number' ? destination : destination.offsetTop;
		const destinationOffsetToScroll = Math.round(documentHeight - destinationOffset < windowHeight ? documentHeight - windowHeight : destinationOffset);
		const easingChoice = this.easings[easing];

		if (!('requestAnimationFrame' in window)) {
			window.scroll(0, destinationOffsetToScroll);
			return;
		}

		function scroll() {
			const now = 'now' in window.performance ? performance.now() : new Date().getTime();
			const time = Math.min(1, ((now - startTime) / duration));
			const timeFunction = easingChoice(time);
			window.scroll(0, Math.ceil((timeFunction * (destinationOffsetToScroll - start)) + start));

			if (window.pageYOffset === destinationOffsetToScroll) {
				return;
			}

			requestAnimationFrame(scroll);
		}

		scroll();
	}
}