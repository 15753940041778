import {IdealPostcodes} from 'ideal-postcodes-autocomplete';

export default class AddressLookup {
	constructor(element) {
		if (!(element instanceof HTMLElement)) {
			throw new TypeError('Invalid element');
		}

		this.element 		= element;
		this.group_id 		= this.element.dataset.id;
		this.ele_id   		= this.element.id;
		this.updateEvent 	= new CustomEvent('address:updated');
		this.addressFields 	= document.querySelector('#' + this.group_id + '_address_fields');
		this.hiddenInput    = this.element.closest('.row').querySelector('#' + this.group_id);

		this.initLib();

		this.addressFields.addEventListener('address:updated', this.serializeAddress.bind(this));
		if(this.addressFields.querySelector('input') !== null){
			this.addressFields.querySelectorAll('input').forEach(el => {
				el.addEventListener('keyup', this.serializeAddress.bind(this));
			})
		}
	}

	initLib(){
		if(typeof this.element.dataset.id === 'undefined'){
			return false;
		}

		const controller = new window.IdealPostcodes.Autocomplete.Controller({
			api_key: "ak_ia58382us8Avy5Gylzlgnm0OFvE01",
			inputField: '#' + this.ele_id,
			outputFields: {
				line_1		: '#' + this.group_id + "_line_1",
				line_2		: '#' + this.group_id + "_line_2",
				line_3		: '#' + this.group_id + "_line_3",
				post_town	: '#' + this.group_id + "_post_town",
				postcode	: '#' + this.group_id + "_postcode"
			},
			onOpen: () => {
				this.element.classList.add('list-open');
			},
			onClose: () => {
				this.element.classList.remove('list-open');
			},
			onFailedCheck: () => {
				this.addressFields.classList.add('open');
				this.element.style.display = 'none';
			},
			onSearchError: () => {
				this.addressFields.classList.add('open');
				this.element.style.display = 'none';
			},
			onAddressRetrieved: () => {
				if(this.addressFields !== null){
					setTimeout(ev => {
						this.addressFields.classList.add('open');
						this.addressFields.dispatchEvent(this.updateEvent);
						this.addressFields.scrollIntoView({
							behavior: 'smooth',
							block: 'start',
						});
					}, 100);
				}
			},
		});
	}

	serializeAddress(){
		let fields = {};

		if(this.hiddenInput === null){
			return false;
		}

		if(this.addressFields.querySelector('input') !== null){
			this.addressFields.querySelectorAll('input').forEach(el => {
				let key = el.id.replace(this.group_id + '_', '');
				fields[key] = el.value;
			});
		}

		this.hiddenInput.value = JSON.stringify(fields);
	}
}