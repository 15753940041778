import Swiper from 'swiper/js/swiper';

export default class CardImageSwiper {
	constructor(element) {
		if (!(element instanceof HTMLElement)) {
			throw new TypeError('Invalid element');
		}

		this.element = element;

		this.initSwiper();
	}

	initSwiper(){
		this.swiperObj = new Swiper(this.element, {
			speed: 400,
			allowTouchMove: true,
			effect : 'slide',
			autoHeight: true,
			autoplay: {
				delay: 15000,
			},
			navigation: {
				nextEl: this.element.parentNode.querySelector('.swiper-button-next'),
				prevEl: this.element.parentNode.querySelector('.swiper-button-prev'),
			},
		});
	}
}