import Swiper from 'swiper/js/swiper';

export default class Timeline{
	constructor(element) {
		if(!(element instanceof HTMLElement)) {
			throw new TypeError('Invalid element');
		}

		this.wrapper 	= element;
		this.timeline 	= this.wrapper.querySelector('.timeline__items');
		this.items 		= this.wrapper.querySelectorAll('.timeline__item');
		this.bar 		= this.wrapper.querySelector('.timeline__progress');
		this.copy 		= this.wrapper.querySelector('.timeline__copy');
		this.swiperObj 	= null;


		if(this.items !== null){
			this.initEvents();

			window.addEventListener('resize', this.initEvents.bind(this), { passive: true });
		} else {
			console.warn('[Timeline.js] No Items Found');
		}

		window.addEventListener('resize', this.resizeProgress.bind(this), { passive: true });
	}

	initEvents() {
		if(window.outerWidth > 991){
			this.initDesktopEvents();
		} else{
			if(this.swiperObj === null) {
				this.initSlider();
			}
		}
	}

	initDesktopEvents(){
		if(this.swiperObj !== null){
			this.swiperObj.destroy();
			this.swiperObj = null;
		}

		this.items.forEach(el => {
			el.addEventListener('click', this.moveTimeline.bind(this));
		});

		if(typeof this.copy !== 'undefined'){
			let copy = this.items[0].dataset.copy || '';

			this.copy.innerHTML = copy;
		}
	}

	initSlider(){
		const _self = this;

		this.items.forEach(el => {
			el.removeEventListener('click', this.moveTimeline);
		});

		this.swiperObj =  new Swiper(this.wrapper, {
			speed: 400,
			allowTouchMove: true,
			effect: 'slide',
			autoplay: false,
			on : {
				init: function () {
					let containerWidth = this.$el[0].offsetWidth,
						timelineWidth  = (containerWidth * (this.slides.length - 1));

					document.querySelector(".timeline__items").style.setProperty('--timelinewidth', timelineWidth + 'px');
					document.querySelector(".timeline__items").style.setProperty('--timelineleft', (containerWidth / 2) + 'px');

					if(typeof _self.copy !== 'undefined'){
						let copy = _self.items[0].dataset.copy || '';

						_self.copy.innerHTML = copy;
					}
				},
				slideChange: function(){
					if(typeof _self.copy !== 'undefined'){
						let copy = _self.items[this.activeIndex].dataset.copy || '';

						_self.copy.innerHTML = copy;
					}
				}
			}
		});
	}

	moveTimeline(ev){
		ev.preventDefault();

		let index = [...this.items].indexOf(ev.currentTarget);

		this.items.forEach(el => el.classList.remove('active'));

		for (let i = 0; i <= index; i++){
			this.items[i].classList.add('active');
		}

		if(this.bar !== null){
			let perc = ( 100 / (this.items.length - 1) ) * index;
			this.bar.querySelector('span').style.width = perc + '%';
		}

		if(typeof this.copy !== 'undefined'){
			let copy = this.items[index].dataset.copy || '';

			this.copy.innerHTML = copy;
		}
	}

	resizeProgress(){
		if(this.swiperObj !== null){
			let containerWidth = this.swiperObj.$el[0].offsetWidth,
				timelineWidth  = (containerWidth * (this.swiperObj.slides.length - 1));

			document.querySelector(".timeline__items").style.setProperty('--timelinewidth', timelineWidth + 'px');
			document.querySelector(".timeline__items").style.setProperty('--timelineleft', (containerWidth / 2) + 'px');
		}
	}
}