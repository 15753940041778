import loadGmaps from 'load-google-maps-api';

export default {
	init: function(){
		loadGmaps({ key: 'AIzaSyAc4qeSw9vL5jXPnI12LbPKI7KnoZm5Sa4' }).then( mapsApi => {
			if(document.querySelector('.background-map') === null)
				return false;

			document.querySelectorAll('.background-map').forEach(el => {
				if(typeof el.dataset.lat === 'undefined' || typeof el.dataset.lng === 'undefined')
					return;

				const lat = parseFloat(el.dataset.lat);
				const lng = parseFloat(el.dataset.lng);

				let latLng = { lat: lat, lng: lng + 0.008 };

				if(window.innerWidth  < 992){
					latLng.lng = lng + 0.005;
				}

				if(window.innerWidth  < 767){
					latLng.lng = lng;
					latLng.lat = lat - 0.0017;
				}

				el.map = new mapsApi.Map(el, {
					center: latLng,
					zoom: 16,
					disableDefaultUI: true,
					draggable: false,
					draggableCursor: 'default',
					styles: [
						{
							"featureType": "all",
							"elementType": "labels.text.fill",
							"stylers": [
								{
									"saturation": 36
								},
								{
									"color": "#333333"
								},
								{
									"lightness": 40
								}
							]
						},
						{
							"featureType": "all",
							"elementType": "labels.text.stroke",
							"stylers": [
								{
									"visibility": "on"
								},
								{
									"color": "#ffffff"
								},
								{
									"lightness": 16
								}
							]
						},
						{
							"featureType": "all",
							"elementType": "labels.icon",
							"stylers": [
								{
									"visibility": "off"
								}
							]
						},
						{
							"featureType": "administrative",
							"elementType": "geometry.fill",
							"stylers": [
								{
									"color": "#fefefe"
								},
								{
									"lightness": 20
								}
							]
						},
						{
							"featureType": "administrative",
							"elementType": "geometry.stroke",
							"stylers": [
								{
									"color": "#fefefe"
								},
								{
									"lightness": 17
								},
								{
									"weight": 1.2
								}
							]
						},
						{
							"featureType": "landscape",
							"elementType": "geometry",
							"stylers": [
								{
									"color": "#f5f5f5"
								},
								{
									"lightness": 20
								}
							]
						},
						{
							"featureType": "landscape.man_made",
							"elementType": "geometry.fill",
							"stylers": [
								{
									"color": "#e9eaeb"
								}
							]
						},
						{
							"featureType": "landscape.natural",
							"elementType": "geometry.fill",
							"stylers": [
								{
									"color": "#eef3ea"
								}
							]
						},
						{
							"featureType": "poi",
							"elementType": "geometry",
							"stylers": [
								{
									"color": "#f5f5f5"
								},
								{
									"lightness": "21"
								}
							]
						},
						{
							"featureType": "poi.park",
							"elementType": "geometry",
							"stylers": [
								{
									"color": "#fa1313"
								},
								{
									"lightness": 21
								}
							]
						},
						{
							"featureType": "poi.park",
							"elementType": "geometry.fill",
							"stylers": [
								{
									"color": "#bae692"
								}
							]
						},
						{
							"featureType": "road.highway",
							"elementType": "geometry.fill",
							"stylers": [
								{
									"color": "#ffffff"
								},
								{
									"lightness": 17
								}
							]
						},
						{
							"featureType": "road.highway",
							"elementType": "geometry.stroke",
							"stylers": [
								{
									"color": "#ffffff"
								},
								{
									"lightness": 29
								},
								{
									"weight": 0.2
								}
							]
						},
						{
							"featureType": "road.arterial",
							"elementType": "geometry",
							"stylers": [
								{
									"color": "#ffffff"
								},
								{
									"lightness": 18
								}
							]
						},
						{
							"featureType": "road.local",
							"elementType": "geometry",
							"stylers": [
								{
									"color": "#ffffff"
								},
								{
									"lightness": 16
								}
							]
						},
						{
							"featureType": "transit",
							"elementType": "geometry",
							"stylers": [
								{
									"color": "#f2f2f2"
								},
								{
									"lightness": 19
								}
							]
						},
						{
							"featureType": "water",
							"elementType": "geometry",
							"stylers": [
								{
									"color": "#85cbe7"
								},
								{
									"lightness": 17
								}
							]
						}
					]
				});

				el.marker =  new mapsApi.Marker({
					position: { lat: lat, lng: lng },
					map: el.map,
					icon: {
						// url 		: '/wp/wp-content/themes/class/dist/images/map-marker.png',
						url 		: '/wp-content/themes/class/dist/images/map-marker.png',
						size 		: new mapsApi.Size(288, 288),
						scaledSize  : new mapsApi.Size(144, 144),
						origin 		: new mapsApi.Point(0, 0),
						anchor 		: new mapsApi.Point(72, 144)
					}
				});
			});
		});
	}
};